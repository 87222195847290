<a href="/">
    <img
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultLogoUrl"
        alt="logo"
        class="d-none d-lg-inline h-30px"
    />
    <img
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultSmallLogoUrl"
        alt="logo"
        class="d-lg-none h-25px"
    />

    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="tenantLogoUrl"
        alt="logo"
        class="d-none d-lg-inline h-30px"
    />
    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="tenantSmallLogoUrl"
        alt="logo"
        class="d-lg-none h-25px"
    />
</a>
