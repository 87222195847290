export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'ReMad',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };

    static readonly grid = {
        defaultPageSize: 10,
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '14.0.0';

    /// <summary>
    /// Redirects users to host URL when using subdomain as tenancy name for not existing tenants
    /// </summary>
    static readonly PreventNotExistingTenantSubdomains = false;

     static readonly  RefreshTimeIntervalForWidgets: number  = 60000;
     static readonly  RefreshTimeIntervalForNotification: number  = 180000;
    
     //WizardSteps
     static readonly  WizardStep1: number  = 0;
     static readonly  WizardStep2: number  = 1;
     static readonly  WizardStep3: number  = 2;
     static readonly  WizardStep4: number  = 3;
     static readonly  WizardStep5: number  = 4;
     static readonly  WizardStep6: number  = 5;
     static readonly  WizardStep7: number  = 6;
     static readonly  WizardStep8: number  = 7;
     static readonly  WizardStep9: number  = 8;
}
