<div [@routerTransition] class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Aside-->
        <div
            id="kt_aside"
            class="aside bg-primary"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="auto"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >
            <!--begin::Logo-->
            <div
                class="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8"
                id="kt_aside_logo"
            >
                <theme4-brand></theme4-brand>
            </div>
            <!--end::Logo-->
            <!--begin::Nav-->
            <div
                class="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0"
                id="kt_aside_nav"
            ></div>
            <!--end::Nav-->
            <!--begin::Footer-->
            <div class="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer"></div>
            <!--end::Footer-->
        </div>
        <!--end::Aside-->
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <!--begin::Header-->
            <div id="kt_header" class="header bg-white align-items-stretch">
                <!--begin::Container-->
                <div [class]="containerClass + ' d-flex align-items-stretch justify-content-between'">
                    <!--begin::Aside mobile toggle-->
                    <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                        <div
                            class="btn btn-icon btn-active-color-primary w-40px h-40px"
                            id="kt_header_menu_mobile_toggle"
                        >
                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                            <span class="svg-icon svg-icon-2x mt-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="black"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                    </div>
                    <!--end::Aside mobile toggle-->
                    <!--begin::Mobile logo-->
                    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 d-lg-none">
                        <theme4-brand [customStyle]="'h-25px'"></theme4-brand>
                    </div>
                    <!--end::Mobile logo-->
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <!--begin::Navbar-->
                        <div class="d-flex align-items-stretch" id="kt_header_nav">
                            <div
                                class="header-menu align-items-stretch"
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="end"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <top-bar-menu
                                    [menuClass]="
                                        'menu menu-rounded menu-column menu-lg-row menu-active-bg menu-state-primary menu-title-gray-600 menu-arrow-gray-400 fw-semibold fs-6 my-5 my-lg-0 px-2 px-lg-0 align-items-stretch'
                                    "
                                ></top-bar-menu>
                            </div>
                        </div>
                        <!--end::Navbar-->
                        <!--begin::Topbar-->
                        <div class="d-flex align-items-stretch justify-self-end flex-shrink-0">
                            <active-delegated-users-combo></active-delegated-users-combo>
                            <subscription-notification-bar
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></subscription-notification-bar>
                            <quick-theme-selection
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></quick-theme-selection>
                            <language-switch-dropdown
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></language-switch-dropdown>
                            <header-notifications
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></header-notifications>
                            <chat-toggle-button
                                [customStyle]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></chat-toggle-button>

                            <user-menu
                                class="topbar-item d-flex align-items-center"
                                [iconOnly]="true"
                                [togglerCssClass]="
                                    'btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                                "
                            ></user-menu>
                        </div>
                        <!--end::Topbar-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Container-->
            </div>
            <!--end::Header-->
            <!--begin::Content-->
            <router-outlet></router-outlet>
            <!--end::Content-->
            <!--begin::Footer-->
            <footer-bar></footer-bar>
            <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
